














































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"

@Component({
	components: {
		topNav
	}
})
export default class Default extends Vue {
	integrInfoItemData: { img: any; name: string }[] = [
		{
			img: require("../../assets/img/useDirections.png"),
			name: "使用说明"
		},
		{
			img: require("../../assets/img/orderRecord.png"),
			name: "订单记录"
		}
	]
	CreditScoresData: any = null
	userName: string = ""
	userImg: string = ""
  noPayOrderNum: any=null

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	toItemPage(val: any) {
		console.log(val)
		if (val.name == "订单记录") {
			this.$router.push("/my/orderRecord")
		} else if (val.name == "使用说明") {
			this.$router.push("/my/useDirection")
		}
	}
	created() {
		this.GetUserByIdNew()
		this.CreditScores()
    this.getNopayOrder()
	}

	CreditScores() {
		this.$api.CreditScores().then((res: any) => {
			this.CreditScoresData = res.data
		})
	}

	GetUserByIdNew() {
		this.$api.GetUserByIdNew().then((res: any) => {
			// console.log(res)
			if (res.Data.UserAddonInfo.NickName == null) {
				this.userName = res.Data.Account
			} else {
				this.userName = res.Data.UserAddonInfo.NickName
			}
			this.userImg = res.Data.UserAddonInfo.HeadImage
		})
	}

	toReceiverAddress() {
    this.$router.push("/my/addressList")
	}

	toIntegral() {
		const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		if (u.indexOf("eshebao") != -1) {
			if (isAndroid) {
				;(window as any).eshebao.gotoMyIntegral()
			} else if (isiOS) {
				;(window as any).webkit.messageHandlers.eshebao.postMessage("gotoMyIntegral")
			}
		}
	}
  // 获取未支付订单数量
  getNopayOrder() {
    let obj={
      OrderStatus:0,
      PageIndex: 1,
		  PageSize: 5
    }
    this.$api.orderList(obj).then((res:any)=>{
      this.noPayOrderNum=res.data.totalItemsCount?res.data.totalItemsCount:null;
    })
  }
	historyBack(){
    this.$router.push("/");
  }
}
